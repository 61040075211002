<template>
  <v-dialog
    v-model="display"
    :width="dialogWidth"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        v-bind="textFieldProps"
        :disabled="disabled"
        :loading="loading"
        :label="label"
        :value="formattedDatetime"
        readonly
        v-on="on"
      >
        <template v-slot:progress>
          <slot name="progress">
            <v-progress-linear
              color="primary"
              indeterminate
              absolute
              height="2"
            ></v-progress-linear>
          </slot>
        </template>
      </v-text-field>
    </template>

    <v-card>
      <v-card-text class="px-0 py-0">
        <v-tabs
          v-model="activeTab"
          fixed-tabs
        >
          <v-tab
            v-if="pickDate"
            key="calendar"
          >
            <slot name="dateIcon">
              <v-icon>mdi-calendar-month</v-icon>
            </slot>
          </v-tab>
          <v-tab
            v-if="pickTime"
            key="timer"
            :disabled="dateSelected"
          >
            <slot name="timeIcon">
              <v-icon>mdi-clock</v-icon>
            </slot>
          </v-tab>
          <v-tab-item
            v-if="pickDate"
            key="calendar"
          >
            <v-date-picker
              v-model="date"
              :min="formattedMinDatetime"
              v-bind="datePickerProps"
              full-width
              @input="showTimePicker"
            ></v-date-picker>
          </v-tab-item>
          <v-tab-item
            v-if="pickTime"
            key="timer"
          >
            <v-time-picker
              ref="timer"
              v-model="time"
              class="v-time-picker-custom"
              color="primary"
              v-bind="timePickerProps"
              full-width
              :min="minTime"
              :max="maxTime"
              scrollable
            ></v-time-picker>
          </v-tab-item>
        </v-tabs>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="grey lighten-1"
          text
          @click="display = !display"
        >
          {{ closeText }}
        </v-btn>
        <v-spacer></v-spacer>
        <slot
          name="actions"
          :parent="this"
        >
          <v-btn
            color="grey lighten-1"
            text
            @click.native="clearHandler"
          >
            {{ clearText }}
          </v-btn>
          <v-btn
            color="primary darken-1"
            text
            @click="okHandler"
          >
            {{ okText }}
          </v-btn>
        </slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { format, parse } from 'date-fns'

const DEFAULT_DATE = '0000-00-00'
const DEFAULT_TIME = '00:00:05'
const DEFAULT_DATE_FORMAT = 'yyyy-MM-dd'
const DEFAULT_TIME_FORMAT = 'HH:mm:ss'
const DEFAULT_DIALOG_WIDTH = 340
const DEFAULT_CLEAR_TEXT = 'CLEAR'
const DEFAULT_CLOSE_TEXT = 'CLOSE'
const DEFAULT_OK_TEXT = 'OK'

export default {
  model: {
    prop: 'datetime',
    event: 'input',
  },
  props: {
    datetime: {
      type: [Date, String],
      default: null,
    },
    minDate: {
      type: [Date, String],
      default: null,
    },
    maxDate: {
      type: [Date, String],
      default: null,
    },

    minTime: {
      type: String,
      default: '',
    },
    maxTime: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
    },
    label: {
      type: String,
      default: '',
    },
    dialogWidth: {
      type: Number,
      default: DEFAULT_DIALOG_WIDTH,
    },
    dateFormat: {
      type: String,
      default: DEFAULT_DATE_FORMAT,
    },
    timeFormat: {
      type: String,
      default: DEFAULT_TIME_FORMAT,
    },
    clearText: {
      type: String,
      default: DEFAULT_CLEAR_TEXT,
    },
    closeText: {
      type: String,
      default: DEFAULT_CLOSE_TEXT,
    },
    pickDate: {
      type: Boolean,
      default: true,
    },
    pickTime: {
      type: Boolean,
      default: true,
    },
    okText: {
      type: String,
      default: DEFAULT_OK_TEXT,
    },
    textFieldProps: {
      type: Object,
      default() {
        return {
          'append-icon': 'mdi-calendar',
        }
      },
    },
    datePickerProps: {
      type: Object,
    },
    timePickerProps: {
      type: Object,
    },
  },
  data() {
    return {
      display: false,
      activeTab: 0,
      date: DEFAULT_DATE,
      time: DEFAULT_TIME,
    }
  },
  computed: {
    dateTimeFormat() {
      return `${this.dateFormat} ${this.timeFormat}`
    },
    defaultDateTimeFormat() {
      return `${DEFAULT_DATE_FORMAT} ${DEFAULT_TIME_FORMAT}`
    },
    formattedDatetime() {
      if (!this.pickDate && this.pickTime && this.time) {
        return this.time
      }
      if (!this.pickTime) {
        return this.date
      }

      return this.selectedDatetime ? format(this.selectedDatetime, this.dateTimeFormat) : ''
    },
    formattedMinDatetime() {
      return this.selectedMinDatetime ? format(this.selectedMinDatetime, this.dateTimeFormat) : ''
    },
    selectedDatetime() {
      if (this.pickDate && this.date && this.time) {
        let datetimeString = `${this.date} ${this.time}`
        if (this.time.length === 5) {
          datetimeString += ':00'
        }

        return parse(datetimeString, this.defaultDateTimeFormat, new Date())
      }
      if (!this.pickDate && this.pickTime && this.time) {
        return this.time
        const datetimeString = `${this.date} ${this.time}`

        return parse(`${datetimeString}:00`, this.defaultDateTimeFormat, new Date())
      }

      return null
    },
    selectedMinDatetime() {
      if (this.minDate/* && this.min */) {
        let initDateTime
        if (this.minDate instanceof Date) {
          initDateTime = this.minDate
        } else if (typeof this.minDate === 'string' || this.minDate instanceof String) {
          // see https://stackoverflow.com/a/9436948
          initDateTime = parse(this.minDate, this.dateTimeFormat, new Date())
        }

        return initDateTime

        /* let datetimeString = this.minDate;
        return parse(datetimeString, this.defaultDateTimeFormat, new Date()); */
      }

      return null
    },
    dateSelected() {
      return !this.date
    },
  },
  watch: {
    datetime() {
      this.init()
    },
  },
  mounted() {
    this.init()
  },
  created() {

  },
  methods: {
    init() {
      /* if(!this.pickDate && this.pickTime && this.time){
        this.time = this.datetime;
      }else{ */
      if (!this.datetime) {

        return
      }

      let initDateTime
      if (this.datetime instanceof Date) {
        initDateTime = this.datetime
      } else if (typeof this.datetime === 'string' || this.datetime instanceof String) {
        console.log('is string', parse(this.datetime, this.dateFormat, new Date()))

        // see https://stackoverflow.com/a/9436948
        if (!this.pickTime) {
          initDateTime = parse(this.datetime, this.dateFormat, new Date())
        } else {
          initDateTime = parse(this.datetime, this.dateTimeFormat, new Date())
        }
        initDateTime = this.datetime
      }

      console.log('datetime', initDateTime)
      this.date = this.pickDate ? format(initDateTime, this.dateFormat) : null
      this.time = this.pickTime ? format(initDateTime, DEFAULT_TIME_FORMAT) : null

      console.log('datetime', this.date)

      // }
    },
    okHandler() {
      this.resetPicker()
      this.$emit('input', this.selectedDatetime)
    },
    clearHandler() {
      //  this.resetPicker()
      this.activeTab = 0
      if (this.$refs.timer) {
        this.$refs.timer.selectingHour = true
      }

      this.date = DEFAULT_DATE
      this.time = DEFAULT_TIME
      this.$emit('input', null)
    },
    resetPicker() {
      this.display = false
      this.activeTab = 0
      if (this.$refs.timer) {
        this.$refs.timer.selectingHour = true
      }
    },
    showTimePicker() {
      this.activeTab = 1
    },
  },
}
</script>
